/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }

body {
    margin: 0;
    font-family: Roboto,
    "Helvetica Neue", sans-serif;
    background-color: #F2F5F9 !important;
}

footer {
    width: 100%;
    padding: 10px;
    // background-color: white;
}

.link {
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

.center {
    margin-left: auto !important;
    margin-right: auto !important;
}

.content {
    padding: 30px 30px;
}

div.step-content {
    min-height: 65vh;
    // padding-top: 20px !important;
}

div.ng-wizard-toolbar {
    display: none;
}

.avatar-30 {
    display: flex;
    height: 30px;
    width:  30px;
    border-radius: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 4px;
}

.avatar-70 {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #353535;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    border: solid 1px #ccc;
    display: inline-block;
    vertical-align: middle;
    position:relative;
    
}

label.xng-breadcrumb-trail {
    margin-bottom: 0px;
}

div.unpaid .agm-map-container-inner {
    opacity: .25;
}

div.past .agm-map-container-inner {
    opacity: .25;
}

.map-label{
    background-color: white !important;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #000000;
}

.dashboard-section-title {
    width: 100%;
    align-content: center;
    text-align: center;
    padding: 10px;
    font-size: 20px;
    color: #0670a0;
}

.list-thumbnail-small {
    width: 54px;
    height: 96px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.list-thumbnail-medium {
    width: 108px;
    height: 192px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.dashboard-header {
    width: 100%;
}

.flex-spacer {
    flex: 1 1 auto;
}

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap.scss';